import React from 'react';
import {Form, Col} from 'react-bootstrap';
import {useFormContext,useForm} from 'react-hook-form';

import moment from 'moment';

const YearsMonth = ({years, fieldName}) => {
	
	const {
		register,
		setValue,
		formState: {errors}
	} = useFormContext() || useForm();

	const counter = years + 1 || 4;

	let nowYear = moment().year();

	return (
		<>
			<Form.Group as={Col} lg={2} controlId={`${fieldName}.year`}>
				<Form.Select
					{...register(`${fieldName}.year`)}
					name={`${fieldName}.year`}
					onChange={(e) => setValue(`${fieldName}.year`, e.target.value)}
				>
					{[...Array(counter)].map((y, i) => {
						return (
							<option key={i} value={nowYear - i}>
								{nowYear - i}
							</option>
						);
					})}
				</Form.Select>
			</Form.Group>
			<Form.Group as={Col} lg={1} controlId={`${fieldName}.month`}>
				<Form.Select
					{...register(`${fieldName}.month`)}
					name={`${fieldName}.month`}
					onChange={(e) => setValue(`${fieldName}.month`, e.target.value)}
				>
					{moment.monthsShort().map((m, i) => {
						return (
							<option key={i} value={m}>
								{m}
							</option>
						);
					})}
				</Form.Select>
			</Form.Group>
		</>
	);
};

export default YearsMonth;
