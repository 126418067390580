import React, {useState} from 'react';
import {Form, Row, Col, Button} from 'react-bootstrap';
import {useFormContext, useForm} from 'react-hook-form';
import YearMonth from './yearMonth';
import moment from 'moment';

const TheftAccidents = () => {
	const {
		register,
		unregister,
		setValue,
		formState: {errors}
	} = useFormContext() || useForm();

	const [indexes, setIndexes] = useState([0]);
	const [counter, setCounter] = useState(1);

	const addTheftAccident = () => {
		setIndexes((prevIndexes) => [...prevIndexes, counter]);
		setCounter((prevCounter) => prevCounter + 1);
	};

	const removeTheftAccident = (index) => {
		setIndexes((prevIndexes) => [
			...prevIndexes.filter((item) => item !== index)
		]);
		unregister(`theftAccidents[${index}].type`);
		unregister(`theftAccidents[${index}].year`);
		unregister(`theftAccidents[${index}].month`);
	};

	const theftAccidentTypes = [
		'Collision - Multi Vehicle (At Fault)',
		'Collision - Multi Vehicle (Not at Fault)',
		'Collision - Only vehicle involved (fixed property / parked car / animal / pedestrian)',
		'Fire - Not Total Loss	accidents',
		'Fire - Total Loss	accident',
		'Flood',
		'Hail',
		'Hit while parked',
		'Lost control',
		'Malicious damage',
		'Storm',
		'Windscreen',
		'Theft - Personal property / Accessories / Modifications',
		'Theft - RecoveredLoss of licence - 3-6 months',
		'Loss of licence - 6-12 months',
		'Theft - Unrecovered'
	];

	return (
		<>
			<Row>
				<Col>Description</Col>
				<Col lg={2}>Year</Col>
				<Col lg={1}>Month</Col>
				<Col></Col>
			</Row>

			{indexes.map((index) => {
				const fieldName = `theftAccidents[${index}]`;
				register(`${fieldName}.type`);

				let descInvalid = false;

				if (
					errors.theftAccidents &&
					errors.theftAccidents[`${index}`] !== undefined
				) {
					descInvalid = errors.theftAccidents[`${index}`].type !== undefined;
				}

				return (
					<Row className='mb-3' key={fieldName}>
						<Form.Group as={Col} controlId={`${fieldName}.type`}>
							<Form.Select
								name={`${fieldName}.type`}
								onChange={(e) => setValue(`${fieldName}.type`, e.target.value)}
								isInvalid={descInvalid}
							>
								<option>-- Please select --</option>
								{theftAccidentTypes.map((type) => {
									return <option value={type}>{type}</option>;
								})}
							</Form.Select>
							{descInvalid && (
								<Form.Control.Feedback type='invalid'>
									{errors.theftAccidents[`${index}`].type.message}
								</Form.Control.Feedback>
							)}
						</Form.Group>
						<YearMonth fieldName={fieldName} /> 
						
						<Col>
							{indexes[0] !== index && (
								<Button onClick={() => removeTheftAccident(index)}>
									Remove
								</Button>
							)}{' '}
						</Col>
					</Row>
				);
			})}

			<Row className='mb-5'>
				<Col>
					<Button onClick={addTheftAccident}>Add more</Button>
				</Col>
			</Row>
		</>
	);
};

export default TheftAccidents;
